.review-banner {
    background:linear-gradient(to right, #c65dc2, #1dbdf6);
    box-shadow: 0em 0.5em 1em -0.4em inset black;
    
    text-align: center;
    align-items: center;

    min-height: 250px;

    padding: 2vh 1vw;
}
.review-banner a {
    color: #f0f8ff;
}
.review-banner a:hover{
    color: #f0f8ff;
}

.hidden{
    display: none;
}


@media all and (min-width:575px) {
    .review-banner {
        min-height: 15vh;
    }
}