@media all and (min-width: 575px)
{
    #home-page {
        display: block;
        
    }
    #button-list {
        padding-left: 20%;
        padding-right: 20%;
    }
    #button-box-text {
        padding-left: 5%;
        padding-right: 5%;
    }
}
