
*{
    font-family: sans-serif;
}
h1, h2 {
    font-family: serif;
    font-weight: 500;
}
h2 {
    font-size: 3.0em;
}
h3 {
    font-size: 1.5em;
}
h4 {
    font-size: 1.1em;
}

p {
    font-weight: normal;
}

body {
    background-color: black !important;
    color: aliceblue;
}
span {
    color: aliceblue !important;
}

a{
    text-decoration: none !important;
    color: #000;
    transition: 0.5s;
}

a:hover{
    text-decoration: none !important;
    transition: 0.5s;
}




footer {

    color: aliceblue;
    margin:1em;
    margin-top: 2em;
    display: block;
    float: right;
}


#overlay{
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.55);

    justify-content: center;
    align-items: center;
    text-align: center;
}
#overlay>h1 {
    margin-top: 5vh;
}
#overlay>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 5%;
}