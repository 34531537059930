#social-links {
    width: 100%;
}
#social-links>* {
    font-size: 2em;
    padding: 0 0.5em;
}

#social-links>a:hover{
    color: #b66be2;
}