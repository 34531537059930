#gallery img {
    padding: 1%;
    width: 100%;
}

#gallery div {
    padding: 0%;
    height: min-content;
}

#gallery {
    margin: auto 2vw;
}

#quick-up {
    position: fixed;
    right: 3%;
    bottom: 10%;

    background-color: rgba(128, 128, 128, 0.365);
    border-color: aliceblue;

    width: 50px;
    height: 50px;

    border-radius: 50%;

}
