h1 {
    font-size: xx-large;
}

#banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: bold;
}
#banner-overlay{
    background-color:rgb(30,30,30,0.5);
    padding: 2vh 2vw;
}

a {
    color: aliceblue;
}
a:hover {
    text-decoration: none;
    color: rgb(178, 184, 189);
}
form {
    padding: 2vh 2vw;
}


input, textarea, button, span {
    border-radius: 0% !important;
}

form button{
    background-color: aliceblue !important;
    color: black !important;
    border: 0px !important;
}

#contact-page .row{
    padding: 2vh 2vw;
}
#socials div {
    margin-bottom: 1vh;
}

#contact-form {
    /* display: none; */
    padding: 0%;
}
#contact-form a {
    background-color: aliceblue;
    border:none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1% auto;
    border-radius: 0%;

    text-align: center;
}

#submit-row {
    text-align: right;
}


#social-links>* {
    padding-left: 0px;
}