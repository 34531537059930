
#home-page {
    display: flex;
    flex-direction: column-reverse;
}

#logo-box {
    text-align: center;
    margin: 5% auto;
}
#logo{
    width:50%;
    border-radius: 20px;
    box-shadow: 12px 12px 20px rgba(200,0,0,.08);
}

#button-box {
    color: black;
    background-color: grey;
    text-align: center;
    padding-top:5%;
    padding-bottom: 5%;
}


#button-list{
    padding: 0 10%;
}
#button-list>a{
    background-color: aliceblue;
    border:none;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1% auto;

    transition: ease-out 0.2s;

}
#button-list>a:hover{
    background-color: rgb(46, 46, 46);
    color: #b66be2;

    transition: ease-in 0.25s;
    
}
#social-icons {
    justify-content: center;
}