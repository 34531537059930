nav {
    font-weight: bold;
}
nav a {
    border-right: 1px solid #b66be2;
    color: #9045bd !important;
    font-weight: bold;
    text-align: right;
    padding: 0 1vw !important;
}

nav a:hover {
    color: #462356 !important;
}


@media all and (min-width:768px)
{

    .navbar-collapse{
        justify-content: end;
    }
    nav a:last-child {
        border-right: 0px;
    }
    
}