.price-row {
    align-items: center;
}
.price-row div {
    padding: 2%;
}

.price-row img {
    width: 100%;
}

.price-row:nth-child(2n) {
    text-align: right;
}

.double-image {
    position: relative;
    text-align: center;
    padding-bottom:10%;
    height: 100%;
}
.double-image img {
    position: relative;
    max-width: 50%;
}
.double-image img:first-child{
    right: -30px;
}
.double-image img:nth-child(2) {
    left: -30px;
    bottom: -25px;
}

#full-scene div {
    padding: 1%;
}

.center-text{
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-family: sans-serif;
}